import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { ConcenetError } from '@app/types/error';
import { LANDING_CONFIGURATION_MOCK } from '@data/mocks/landing-configuration.mock';
import { SEND_CUSTOMER_CREDENTIALS_MOCK } from '@data/mocks/send-customer-credentials.mock';
import { USER_REDUCED_VALIDATION_MOCK } from '@data/mocks/user-customer-reducer-validation.mock';
import FileDTO from '@data/models/file.dto';
import LandingConfigurationDTO, { LandingBannerDTO } from '@data/models/landing-configuration.dto';
import UserCustomerReducedDTO from '@data/models/userCustomerReduced.dto';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  public configurationSubject$: BehaviorSubject<LandingConfigurationDTO> = new BehaviorSubject(null);
  private readonly LANDING_PATH = '/api/landing';
  private readonly CONFIGURATION_PATH = '/config';
  private readonly GET_CONFIGURATION_PATH = '/getConfig';
  private readonly VALIDATION_PATH = '/validateCustomer';
  private readonly SEND_CREDENTIALS_PATH = '/sendCustomerCredentials';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}
  public getLandingConfiguration(): Observable<LandingConfigurationDTO> {
    // return of(null).pipe(delay(3000));
    return this.http
      .get<LandingConfigurationDTO>(`${this.env.apiBaseUrl}${this.LANDING_PATH}${this.GET_CONFIGURATION_PATH}`)
      .pipe(catchError((error) => throwError(error.error as ConcenetError)));
  }

  public validateCustomerByDni(dni: string): Observable<UserCustomerReducedDTO> {
    // return of(USER_REDUCED_VALIDATION_MOCK).pipe(delay(3000));
    return this.http
      .get<UserCustomerReducedDTO>(`${this.env.apiBaseUrl}${this.LANDING_PATH}${this.VALIDATION_PATH}/${dni}`)
      .pipe(catchError((error) => throwError(error.error as ConcenetError)));
  }

  public sendPassword(dni: string, sendType: string): Observable<UserCustomerReducedDTO> {
    // return of(SEND_CUSTOMER_CREDENTIALS_MOCK).pipe(delay(3000));
    return this.http
      .get<UserCustomerReducedDTO>(`${this.env.apiBaseUrl}${this.LANDING_PATH}${this.SEND_CREDENTIALS_PATH}/${dni}/${sendType}`)
      .pipe(catchError((error) => throwError(error.error as ConcenetError)));
  }

  public getBase64FromFileDto(file: FileDTO): string {
    if (file.content && file.type) {
      return `data:${file.type} ;base64,${file.content}`;
    }
    return '';
  }

  public getLogoBase64(type: 'main' | 'alt' = 'main'): string {
    let logo: FileDTO = null;
    if (!this.configurationSubject$?.value) {
      return '';
    }
    if (type === 'main' || !this.configurationSubject$?.value?.logoAlt) {
      logo = this.configurationSubject$?.value?.logo;
    } else {
      logo = this.configurationSubject$?.value?.logoAlt;
    }
    if (logo?.content && logo?.type) {
      return `data:${logo.type} ;base64,${logo.content}`;
    }
    return '';
  }
}
