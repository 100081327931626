import { animate, style, transition, trigger } from '@angular/animations';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ENV } from '@app/constants/global.constants';
import { AuthenticationService } from '@app/security/authentication.service';
import { Env } from '@app/types/env';
import { ConcenetError } from '@app/types/error';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LANDING_CONFIGURATION_MOCK } from '@data/mocks/landing-configuration.mock';
import FileDTO from '@data/models/file.dto';
import LandingConfigurationDTO from '@data/models/landing-configuration.dto';
import { LandingService } from '@data/services/landing.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { NGXLogger } from 'ngx-logger';
import { finalize, take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      // transition(':enter', [style({ height: 0, opacity: 0 }), animate('1s ease-out', style({ height: 300, opacity: 1 }))]),
      transition(':leave', [style({ height: 300, opacity: 1 }), animate('1s ease-in', style({ height: 0, opacity: 0 }))])
    ])
  ]
})
export class AppComponent implements OnInit {
  @HostBinding('class') componentCssClass: string;
  public loadingBackgroundImage = true;
  public backgroundImage: string = null;
  private readonly maxTimeToWaitFormBgImage = 10000;

  constructor(
    @Inject(ENV) private env: Env,
    private meta: Meta,
    private logger: NGXLogger,
    private landingService: LandingService,
    private authService: AuthenticationService,
    private globalMessageService: GlobalMessageService,
    public overlayContainer: OverlayContainer,
    private translateService: TranslateService
  ) {}

  @HostListener('window:resize', ['$event']) onResize(event: { target: { innerHeight: number } }) {
    this.setVhProperty();
  }

  ngOnInit() {
    this.addBuildInfoMetatag();
    this.getLandingConfiguration();
    setTimeout(() => {
      this.loadingBackgroundImage = false;
    }, this.maxTimeToWaitFormBgImage);

    this.setVhProperty();
  }

  public isLogged(): boolean {
    return this.authService.isUserLogged();
  }

  private addBuildInfoMetatag(): void {
    this.logger.info('Adding build version info to <head>:', this.env.appVersion);
    this.meta.updateTag({
      name: 'version',
      content: `${this.env.appVersion}`
    });
  }

  private setVhProperty(): void {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    let size = 100;
    const step = 10;
    while (size > 0) {
      document.documentElement.style.setProperty(`--${size}vh`, (vh * size) / 100 + 'px');
      document.documentElement.style.setProperty(`--${size}vw`, (vw * size) / 100 + 'px');
      size -= step;
    }
  }

  private getLandingConfiguration(): void {
    this.landingService
      .getLandingConfiguration()
      .pipe(
        take(1),
        finalize(() => {
          this.loadingBackgroundImage = false;
        })
      )
      .subscribe({
        next: (config: LandingConfigurationDTO) => {
          this.landingService.configurationSubject$.next(config);
          this.backgroundImage = config?.backgroundImage?.content
            ? this.landingService.getBase64FromFileDto(config.backgroundImage)
            : LANDING_CONFIGURATION_MOCK.backgroundImage;
          if (config.landingTheme) {
            this.overlayContainer.getContainerElement().classList.add(config.landingTheme.classTheme);
            this.componentCssClass = config.landingTheme.classTheme;
          }
          // this.overlayContainer.getContainerElement().classList.add('black-theme');
          // this.componentCssClass = 'black-theme';
        },
        error: (err: ConcenetError) => {
          this.backgroundImage = LANDING_CONFIGURATION_MOCK.backgroundImage;
          this.globalMessageService.showError({
            message: err.message,
            actionText: this.translateService.instant(marker('common.close'))
          });
        }
      });
  }
}
