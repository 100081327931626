import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants, RoutingUseHash } from '@app/constants/route.constants';
import { AuthGuardService } from '@app/security/guards/authentication.guard';

const routes: Routes = [
  {
    path: RouteConstants.LOGIN,
    loadChildren: () => import('./modules/app-modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: RouteConstants.LANDING,
    loadChildren: () => import('./modules/app-modules/landing/landing.module').then((m) => m.LandingModule),
    canActivate: [AuthGuardService]
  },
  {
    path: RouteConstants.OTHER,
    pathMatch: 'full',
    redirectTo: RouteConstants.LANDING
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: RoutingUseHash })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
