export const RoutingUseHash = true;
export class RouteConstants {
  // Common
  public static readonly EMPTY = '';
  public static readonly CREATE = 'create';
  public static readonly CLONE = 'clone';
  public static readonly EDIT = 'edit';
  public static readonly DISPLAY = 'display';
  public static readonly BACK = 'back';
  public static readonly OTHER = '**';

  // App
  public static readonly LOGIN = 'login';
  public static readonly LOGIN_DNI = 'dni';
  public static readonly LOGIN_SEND_PASSWORD = 'sendPassword';
  public static readonly LOGIN_INSERT_PASSWORD = 'insertPassword';

  public static readonly LANDING = 'landing';

  //Landing
  public static readonly ORDER_LIST = 'orderList';
  public static readonly ALL_ORDERS_LIST = 'allOrdersList';
  public static readonly ORDER_DETAIL = 'orderDetail';
  public static readonly PAYMENT = 'payment';

  // Route Params
  public static readonly ID = '/:id';
  public static readonly PAYMENT_RESULT = '/:payment_result';
  public static readonly PAYMENT_ID = '/:payment_id';
}
