<h1 mat-dialog-title class="mat-dialog-title">
    {{ title }}
</h1>

<div mat-dialog-content class="mat-dialog-content">
    <p>{{ message }}</p>
</div>

<div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button (click)="onDismiss()">
        {{ noActionText }}
    </button>
    <button mat-flat-button color="primary" (click)="onConfirm()">
        {{ yesActionText }}
    </button>
</div>
