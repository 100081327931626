<div class="landing mat-app-background flex align-items-center justify-content-center">
    <div
        class="landing__loading flex align-items-center justify-content-center w-full h-full"
        *ngIf="loadingBackgroundImage"
        [@inOutAnimation]
    >
        <img src="./assets/icons/logo.png" class="logo" />
    </div>
    <img [src]="backgroundImage" *ngIf="backgroundImage" class="landing__bg-img w-full h-full" />
    <!-- <div class="landing__logos flex justify-content-center align-items-center">
        <img *ngFor="let logo of logos" [src]="logo.content" />
    </div> -->
    <div class="landing__content" [ngClass]="{ 'full-screen': isLogged() }">
        <router-outlet></router-outlet>
    </div>
</div>
