import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { ConcenetError } from '@app/types/error';
import LoginDTO from '@data/models/login.dto';
import UserCustomerReducedDTO from '@data/models/userCustomerReduced.dto';
import { UserService } from '@data/services/user.service';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  public readonly LOGIN_PATH = '/api/landing/login';

  private readonly ACCESS_TOKEN = 'access_token';
  private readonly PROJECT_VERSION = 'project_version';
  private readonly EXPIRES_IN = 'expires_in';
  private readonly TOKEN_TIMESTAMP = 'token_timestamp';
  private readonly USER_ID = 'user_id';
  private readonly USER_DNI = 'user_dni';
  private readonly USER_FULL_NAME = 'user_full_name';
  private readonly USER_CUSTOMER = 'user_customer';

  constructor(
    @Inject(ENV) private env: Env,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnDestroy(): void {}

  public signIn(credentials: { userName: string; password: string }): Observable<LoginDTO> {
    return this.http
      .post<LoginDTO>(`${this.env.apiBaseUrl}${this.LOGIN_PATH}`, credentials)
      .pipe(catchError((error) => throwError(error as ConcenetError)));
  }

  /**
   * Stores a token
   *
   * @param token token to be stored
   */
  setToken(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  /**
   * Retrieves the saved token
   *
   * @returns saved token
   */
  getToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  /**
   * Remove the saved token
   */
  removeToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
  }

  /**
   * Retrieves the userId
   *
   * @returns the userId
   */
  getUserId() {
    return localStorage.getItem(this.USER_ID);
  }

  /**
   * Remove the userId
   */
  removeUserId() {
    localStorage.removeItem(this.USER_ID);
  }
  /**
   * Remove the saved project_version
   */
  removeProjectVersion() {
    localStorage.removeItem(this.PROJECT_VERSION);
  }

  /**
   * Retrieves the userId
   *
   * @returns the project version
   */
  getProjectVersion() {
    return localStorage.getItem(this.PROJECT_VERSION);
  }

  /**
   * Retrieves the userDni
   *
   * @returns the userDni
   */
  getUserDni() {
    return localStorage.getItem(this.USER_DNI);
  }

  /**
   * Retrieves the userDni
   *
   * @returns the userDni
   */
  setUserDni(dni: string) {
    localStorage.setItem(this.USER_DNI, dni);
  }

  /**
   * Remove the userDni
   */
  removeUserDni() {
    localStorage.removeItem(this.USER_DNI);
  }

  /**
   * Retrieves the user customer
   *
   * @returns the UserCustomer
   */
  getUserCustomer(): UserCustomerReducedDTO {
    const user = localStorage.getItem(this.USER_CUSTOMER);
    return user ? JSON.parse(user) : null;
  }

  /**
   * Set the  user customer
   *
   * @returns the UserCustomer
   */
  setUserCustomer(userCustomer: UserCustomerReducedDTO) {
    localStorage.setItem(this.USER_CUSTOMER, JSON.stringify(userCustomer));
  }

  /**
   * Remove the UserCustomer
   */
  removeUserCustomer() {
    localStorage.removeItem(this.USER_CUSTOMER);
  }

  /**
   * Stores the token expires_in
   *
   * @param time token expires_in
   */
  setExpiresIn(time: string) {
    localStorage.setItem(this.EXPIRES_IN, time);
  }

  /**
   * Retrieves the token expires_in
   *
   * @returns the token expires_in
   */
  getExpiresIn() {
    return parseInt(localStorage.getItem(this.EXPIRES_IN), 10);
  }

  /**
   * Remove the saved expires_in
   */
  removeExpiresIn() {
    localStorage.removeItem(this.EXPIRES_IN);
  }

  /**
   * Stores the token token_timestamp
   *
   * @param timestamp token token_timestamp
   */
  setTokenTimestamp(timestamp: number) {
    localStorage.setItem(this.TOKEN_TIMESTAMP, timestamp.toString());
  }

  /**
   * Retrieves the token token_timestamp
   *
   * @returns the token_timestamp
   */
  getTokenTimestamp() {
    return parseInt(localStorage.getItem(this.TOKEN_TIMESTAMP), 10);
  }

  /**
   * Remove the saved token_timestamp
   */
  removeTokenTimestamp() {
    localStorage.removeItem(this.TOKEN_TIMESTAMP);
  }

  /**
   * Retrieves the user full name
   *
   * @returns the userFullName
   */
  getUserFullName() {
    return localStorage.getItem(this.USER_FULL_NAME);
  }

  /**
   * Remove the userFullName
   */
  removeUserFullName() {
    localStorage.removeItem(this.USER_FULL_NAME);
  }

  /**
   * Stores the Logged user
   *
   * @param loginData
   */
  setLoggedUser(loginData: LoginDTO): void {
    this.setTokenData(loginData);
    localStorage.setItem(this.USER_ID, loginData.user.id.toString());
    localStorage.setItem(
      this.USER_FULL_NAME,
      loginData?.user?.fullName ? loginData.user.fullName : loginData?.user?.customer?.fullName
    );
  }

  setTokenData(loginData: LoginDTO): void {
    localStorage.setItem(this.ACCESS_TOKEN, loginData.access_token);
    localStorage.setItem(this.EXPIRES_IN, loginData.expires_in.toString());
    localStorage.setItem(this.TOKEN_TIMESTAMP, (+new Date()).toString());
    localStorage.setItem(this.PROJECT_VERSION, loginData.project_version);
  }

  /**
   * Retrieves current logged user access_token / expires_in / user_id / user_full_name
   */
  getLoggedUser(): {
    access_token: string;
    expires_in: number;
    user_id: string;
    user_customer: UserCustomerReducedDTO;
    user_full_name: string;
  } {
    return {
      [this.ACCESS_TOKEN]: this.getToken(),
      [this.EXPIRES_IN]: this.getExpiresIn(),
      [this.USER_ID]: this.getUserId(),
      [this.USER_CUSTOMER]: this.getUserCustomer(),
      [this.USER_FULL_NAME]: this.getUserFullName()
    };
  }

  /**
   * Check if there is a logged in user
   */
  isUserLogged(): boolean {
    const timeExpiration = moment(this.getTokenTimestamp() + this.getExpiresIn());
    return !!this.getToken() && timeExpiration.isAfter(moment(), 'second');
  }

  /**
   * Log the current user out by removing credentials and log out from the system
   */
  logout(): void {
    this.removeToken();
    this.removeExpiresIn();
    this.removeProjectVersion();
    this.removeTokenTimestamp();
    this.removeUserId();
    this.removeUserDni();
    this.removeUserCustomer();
    this.userService.userLogged$.next(null);
  }
}
