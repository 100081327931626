import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RouteConstants } from '@app/constants/route.constants';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userLogged = this.authenticationService.isUserLogged();
    if (!userLogged) {
      console.log('AUTH GUARD => LOGING OUT');
      this.authenticationService.logout();
      this.router.navigate([RouteConstants.LOGIN]);
    }
    return userLogged;
  }
}
