import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import { ZoomDirective } from './directives/zoomImage.directive';
import { MaterialModule } from './material.module';
import { CustomDialogModule } from './modules/custom-dialog/custom-dialog.module';
import { CustomDialogService } from './modules/custom-dialog/services/custom-dialog.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { GlobalMessageService } from './services/global-message.service';
import { ProgressSpinnerDialogService } from './services/progress-spinner-dialog.service';

@NgModule({
  declarations: [ProgressSpinnerDialogComponent, ConfirmDialogComponent, ZoomDirective],
  imports: [
    TranslateModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomDialogModule
  ],
  providers: [GlobalMessageService, ConfirmDialogService, ProgressSpinnerDialogService, CustomDialogService],
  exports: [TranslateModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule, ZoomDirective, CustomDialogModule]
})
export class SharedModule {}
