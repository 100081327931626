import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import UserDTO from '@data/models/user.dto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userLogged$: BehaviorSubject<UserDTO> = new BehaviorSubject(null);
  private readonly USER_PATH = '/api/users';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}
}
